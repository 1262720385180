<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-12 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">                                 
                                  <el-row>
                                      <el-col :span="12" align="left" v-if="view.website_details">
                                        <span v-loadimage="view.website_details.website_logo" ></span>                                           
                                      </el-col>
                                      <el-col :span="12" align="right">
                                            <b >
                                      <label class="mt-10">{{view.website_details.address}}, {{view.website_details.city_details.city_name}},{{view.website_details.state_details.state_name}} - {{view.website_details.post_code}}, {{view.website_details.country}}.</label><br>                                      
                                      <label>Phone: {{view.website_details.phone}}</label><br>
                                      <label>Fax: {{view.website_details.fax}}</label><br>
                                      <label>Email: {{view.website_details.email_address}}</label>
                                      </b>
                                      <br>
                                      </el-col>
                                  </el-row>
                                  <hr class="invoice-line">
                                  <el-row>                                    
                                      <el-col :span="24" align="center" v-if="form.payment_status == 'paid'">
                                      <h2 style="font-family: Arial;text-align: center;"><b>REFUND ORDER</b></h2>
                                      </el-col>  
                                      <el-col :span="24" align="center" v-if="form.payment_status != 'paid'">
                                      <h2 style="font-family: Arial;text-align: center;"><b>CANCEL ORDER</b></h2>
                                      </el-col>                                                                             
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>ORDER : </b></span><span><b> {{view.order_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>CUSTOMER ID : </b></span><span><b>{{view.customer.customer_id}}</b></span>
                                       </el-col>                                     
                                  </el-row> 
                                   <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO : </div>
                                          <div style="padding: 3px" v-if="view.customer.company_name">{{view.customer.company_name}}</div>
                                          <div style="padding:3px" v-if="view.customer"><span v-if="view.customer.first_name">{{view.customer.first_name}}</span><span v-if="view.customer.last_name">{{view.customer.last_name}}</span></div>
                                          <div style="padding: 3px;" ><span v-if="view.billing_address_order.address_line_2">{{view.billing_address_order.address_line_2}}</span><span v-if="view.billing_address_order.address_line_1">, Unit No : {{view.billing_address_order.address_line_1 }}</span></div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.city_details">{{view.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.state_details">{{view.billing_address_order.state_details.state_name}}<span v-if="view.billing_address_order.post_code"> - {{view.billing_address_order.post_code}}</span></div>
                                      </el-col>
                                      <el-col :span="8" align="left" :offset="8" v-if="view.shipping_address_order">
                                         <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                         <div style="padding: 3px" v-if="view.customer.company_name">{{view.customer.company_name}}</div>
                                         <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name==null?'':view.customer.last_name):''}}</div>
                                         <div style="padding: 3px;" ><span v-if="view.shipping_address_order.address_line_2">{{view.shipping_address_order.address_line_2}}</span><span v-if="view.shipping_address_order.address_line_1">, Unit No : {{view.shipping_address_order.address_line_1 }}</span></div>
                                         <div style="padding: 3px;" v-if="view.shipping_address_order.city_details">{{view.shipping_address_order.city_details.city_name}}</div>
                                         <div style="padding: 3px;" v-if="view.shipping_address_order.state_details">{{view.shipping_address_order.state_details.state_name}}<span v-if="view.shipping_address_order.post_code"> - {{view.shipping_address_order.post_code}}</span></div>
                                      </el-col>
                                    </el-row>                                  
                                      <div class="row">
                                      <div class="col-xl-12 "> 
                                          <el-table :data="form.products" stripe border fit  style="width:100%"  @selection-change="handleSelectionChange">
                                            <el-table-column
                                                type="selection"
                                                width="55">
                                            </el-table-column>                                            
                                            <el-table-column label="Product #" width="100">
                                                <template slot-scope="scope">
                                                  <span>{{form.products[scope.$index].product_item.sku}}</span>                                         
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Product Name" :min-width="200">
                                                <template slot-scope="scope">
                                                  <span>{{form.products[scope.$index].product_item.name}}</span>                                         
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Quantity" width="100" align="center">
                                                <template slot-scope="scope">
                                                  <span>{{form.products[scope.$index].back_quantity}}</span>                                         
                                                </template>
                                            </el-table-column>                                            
                                            <el-table-column :label="form.payment_status == 'paid'?'Refund Quantity' :'Cancel Quantity'" width="150">
                                                <template slot-scope="scope">
                                                    <el-input-number v-model="form.products[scope.$index].cancel_quantity" :min="0" :max="form.products[scope.$index].back_quantity" 
                                                     @change="getReturnPrice(scope.$index)" :controls="false" :disabled="!scope.row.edited"></el-input-number>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Unit Price" width="100" align="center">
                                                <template slot-scope="scope">
                                                  <span>${{parseFloat(form.products[scope.$index].unit_price).toFixed(2)}}</span>                                          
                                                </template>
                                            </el-table-column>                   
                                            <el-table-column label="Total Price" width="100" align="center">
                                                <template slot-scope="scope">                                        
                                                  <span>${{form.products[scope.$index].refund_sub_total? parseFloat(form.products[scope.$index].refund_sub_total).toFixed(2):'0.00'}}</span>                                         
                                                </template>
                                            </el-table-column>                                  
                                          </el-table>
                                        <el-form >                               
                                        <el-row class="pricing-section mt-5 ">
                                          <el-col :span="16">
                                            <el-col :span="20">                                                            
                                              <el-form-item :label="form.payment_status == 'paid'?'Refund Reason':'Cancel Reason'" prop="comments">
                                                <el-input type="textarea" @input.native="capitalize" :rows="4" v-model="form.cancel_reason"></el-input>
                                              </el-form-item> 
                                              <span class="form-err" v-if="form.payment_status == 'paid'">The Refund reason field is required</span> 
                                              <span class="form-err" v-if="form.payment_status != 'paid'" >{{ form.errors.get("cancel_reason") }}</span>                                                         
                                            </el-col>
                                          </el-col>
                                          <el-col :span="8">
                                            <el-form :label-position="labelPosition" label-width="150px" >
                                             <el-form-item label="Sub Total" prop="discount">
                                                  <el-input v-model="form.sub_total" auto-complete="off" :min="0" class="return_product" disabled></el-input>
                                            </el-form-item> 
                                           <el-form-item label="Cancel Shipping" prop="cancel_shipping" v-if="shippling_charge_status == 1">
                                              <el-input v-model="form.cancel_shipping" auto-complete="nope"  :controls="false" :precision="2" disabled class="return_product"></el-input>
                                          </el-form-item>
                                             <el-form-item label="Carry Amount"  prop="discount">
                                                  <el-input v-model="form.carry_amount" auto-complete="off" :min="0" class="return_product" disabled></el-input>
                                            </el-form-item>  
                                                <el-form-item label="Adjustment Amount" prop="shipping">
                                                    <el-input v-model="form.adjustment_amount" auto-complete="off" :min="0" class="return_product"  
                                                    @change="calculateTotal()"   :disabled="refund_status == true"></el-input>
                                                </el-form-item>
                                                  <el-form-item  :label="'Tax HST(' + tax_per + '%)'" prop="tax_total">
                                              <el-input v-model="form.tax_total" auto-complete="off" :min="0" class="return_product" disabled></el-input>
                                            </el-form-item>
                                                <el-form-item label="Total" prop="total">
                                                    <el-input v-model="form.cancel_amount" auto-complete="off" disabled class="sub-total return_product"></el-input>
                                                </el-form-item>
                                                </el-form>
                                            </el-col>
                                        </el-row>
                                          <el-row class="mb-3 text-right" v-for="(pay,index) in payments" :key="index">
                                            <el-col >                                                                                 
                                              <span class="blc-label" v-if="pay.payment_method == 'Credit Card'">{{pay.brand}} **** **** **** {{pay.last_4}}</span>  
                                                <span class="blc-label" v-else>{{pay.payment_method}} Payment</span> 
                                              <span class="ml-2"> {{pay.payment_date | formatDate}}</span><span style="margin-left:5px;">Paid :</span><span > <b>${{pay.due_amount}}</b></span>
                                            </el-col>
                                          </el-row> 
                                          <el-row style="margin-top:25px;" v-if="form.payment_status == 'paid'" class="refund_method">                                     
                                                  <el-col :span="15" >                                             
                                                  <el-form-item label=" Select Refund Method" prop="verified_by" >
                                                    <el-radio  v-model="form.return_amount_type" label="refund_online" v-if="ROnline_status == true">Refund to Online</el-radio>
                                                    <el-radio  v-model="form.return_amount_type" label="refund_offline" v-if="ROffline_status == true">Refund to Offline</el-radio>
                                                </el-form-item>  
                                                    <span class="form-err" v-if="form.errors.has('return_amount_type')" >{{form.errors.get('return_amount_type')}}</span> 
                                                  </el-col>                                    
                                              </el-row>                                 
                                                <el-row class="mt-5">
                                                    <el-col >
                                                    <el-form-item >
                                                          <el-button type="warning" @click="createDeliveryOrder" :disabled="refund_status == true">
                                                            <span v-if="form.payment_status == 'paid'">Refund Order</span>
                                                            <span v-else>Cancel Order</span>                                                            
                                                          </el-button>
                                                    </el-form-item>
                                                    </el-col>
                                                </el-row> 
                                                </el-form>                          
                                        </div>
                                    </div>  
                               </div>
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getCancelorder} from "@/api/order"
import {getPayments,getPaymentDetails} from "@/api/invoice";
import Form from '@/core/services/api.form.services'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[],      
      labelPosition: "right",      
      api_url:'cancel/order',
      ROnline_status : true,
      ROffline_status : true,
       form : new Form({
        order_id:null,
        customer_id:null,
        cancel_reason:null,
        sub_total:0,
        tax_total:0,
        adjustment_amount:'$0.00',
        carry_amount : '$0.00',
        cancel_shipping : '$0.00',
        cancel_amount:0,
        return_amount_type:null,
        payment_status:null,     
          products: [
           {
             id :null,
             product_id: null,                       
             quantity: null, 
             back_quantity:null,
             unit_price:null,
             total_price:null,            
             cancel_quantity:null, 
             inside_per:null, 
             inside_product_price:null,           
             edited:false,
             refund_carry_price:0,
             product_item: [{
               sku: null,
               name:null
             }]            
           }
         ],
         type:null,
         credit_memo_amount : 0,

       }),
       tax_per: 0,
       submit_btn_text:'Cancel Order', 
       payments:[],
       multipleSelection:[], 
       refund_status : true,
       paid_amount: 0,
       cancelAmount:0,
       shippling_charge_status : 0,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      //{ title: "Back orders", route: "/order/list" },
      { title: "Cancel Order" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  methods: {
    fetchData() {
      this.loading = true
      getCancelorder(this.$route.params.order_id).then(response => {  
        if(response.data.data.invoice) {
            this.paid_amount = response.data.data.invoice.total_amount - response.data.data.invoice.balance_amount
        } 
        
        this.view = response.data.data 
        this.form.payment_status = this.view.payment_status;              
        this.form.products = response.data.data.order_items;
        this.bakorder = response.data.backorder; 
        this.form.order_id = response.data.data.id; 
        this.form.customer_id = response.data.data.customer.id 
        this.tax_per = this.view.tax_per ? this.view.tax_per : 0;
         for(let i=0;i< this.form.products.length; i++){          
          this.getReturnPrice(i);
        }    
        if(response.data.data.invoice){
          this.getPayments(response.data.data.invoice.id);
        getPayments(response.data.data.invoice.id).then(response => {
          this.payments = response.data.data
          this.loading = false;
        }) 
        }
         
        this.calculateTotal(); 
        this.loading = false
      });
    },
     handleSelectionChange(val) { 
        this.refund_status = true     
       this.multipleSelection = val;  
       let i=0;
       this.form.products.map((check)=>{
          check.cancel_quantity = 0;  
          check.edited = false       
          val.map((selected)=>{
            if(check.id == selected.id){
                check.cancel_quantity =  check.back_quantity 
                check.edited = true                
              }
            })
          this.getReturnPrice(i++);
       })       
       if(val.length>0){
         this.refund_status = false
       }
      },
       checkAmount(amount){     
      this.form.errors.add("due_amount", "");
      let due_amount = parseFloat(amount).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);       
      this.form.payment_method = ''; 
      if(due_amount != null){
         if(parseFloat(this.invoiceAmount) <= due_amount[0] ){   
            this.form.due_amount = this.invoice.balance_amount;
            this.form.errors.add("due_amount", "Please Enter max Amount $ "+ this.invoice.balance_amount +" Only");
         }else{
            this.form.due_amount = parseFloat(due_amount).toFixed(2);
            if(this.term_condition == 1){
              if(this.form.term_limit >= due_amount[0]){               
                  this.term_condition = 0
                  this.overwrite_condition = 0;
              } 
            }
         }

      }else{
         this.form.due_amount = ''
         this.form.errors.add("due_amount", "Please Enter Amount Only");
      }
     
    },
    getReturnPrice(index){
      //let pid = this.form.products[index].product_id;   
      let inside_per = this.form.products[index].inside_per; 
      let unit_price = this.form.products[index].unit_price; 
      const inside_price = this.form.products[index].inside_product_price
      let inside = 0;
      if(inside_price >0){
        inside = (unit_price * inside_per) / 100;
      } 
       
      let quantity = this.form.products[index].cancel_quantity ? this.form.products[index].cancel_quantity : 0;
      this.shippling_charge_status = 0; 
      if(quantity == this.form.products[index].quantity){
        this.shippling_charge_status = 1;
      }
      this.form.products[index].refund_carry_price = (quantity*inside);   
      this.form.products[index].refund_sub_total = (quantity*unit_price);  
      this.calculateTotal();
    },
    
    calculateTotal() {			
        let sub_totals = 0;  
        let inside_price = 0;   
       let shipping = 0 ;
        let adjustment = this.form.adjustment_amount.replace(/\$|,/g, '')?this.form.adjustment_amount.replace(/\$|,/g, ''):0.00; 
        var adj1 = parseFloat(adjustment).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);       
        if(adj1 != null){
          this.form.adjustment_amount = '$' +adj1;
        }else{
          this.form.adjustment_amount = '$0.00';
          adjustment = 0
        }
                 
        this.form.products.map((product) => {
            sub_totals = sub_totals + product.refund_sub_total; 
            inside_price = inside_price + product.refund_carry_price;
        })

        let tax = 0;
        if(sub_totals>0){
          if(this.shippling_charge_status == 1){
             this.form.cancel_shipping = '$' + parseFloat(this.view.shipping).toFixed(2);
             shipping = this.view.shipping
          }
         
          if(this.view.tax_per){
            if(this.view.adjustment_type == 0){
              tax = (((sub_totals + inside_price + shipping - this.view.adjustment_price) * this.view.tax_per) / 100);
            }else{
              tax = (((sub_totals + inside_price + shipping + this.view.adjustment_price) * this.view.tax_per) / 100);
            }
          }
        }
        
        this.form.sub_total = '$' +parseFloat(sub_totals).toFixed(2);
        this.form.carry_amount = '$' +parseFloat(inside_price).toFixed(2);
        this.form.tax_total = '$' +parseFloat(tax).toFixed(2);

         if(adj1 != null){         
          if(adj1 > (sub_totals+ tax)){
             this.form.adjustment_amount = '$0.00';
             adj1 = 0
             const h = this.$createElement;
            this.$notify({
              message: h(
                "i",
                {
                  style: "color: red",
                },
                "Adjustment price less order amount only"
              ),
              offset: 200,
              type: "warning",
            });
          }
        }
        
        
       
        this.form.cancel_amount = '$0.00'
        if(sub_totals>0){
        if(this.view.adjustment_price>0){
          this.form.adjustment_amount = '$'+parseFloat(this.view.adjustment_price).toFixed(2)  
        }  
        this.cancelAmount = parseFloat(sub_totals + inside_price + shipping - adj1 + tax - this.view.adjustment_price).toFixed(2);
        this.form.cancel_amount = '$' +parseFloat(sub_totals + inside_price - adj1 + tax + shipping - this.view.adjustment_price).toFixed(2);
        }
       
       
    },
    getPayments(invoice_id){
        getPaymentDetails(invoice_id).then(response => {
          let payment = response.data.data;               
          let paymentCount = Object.keys(payment).length;
          // let paymentMethod  = Object.keys(payment);          
          if(paymentCount == 1){
            if(Object.keys(payment) == 'Term'){ 
              this.ROnline_status = false;
              this.ROffline_status = true;
              this.form.return_amount_type= 'refund_offline';
            }else if(Object.keys(payment) == 'Credit Card'){ 
               if(Object.values(payment) > this.form.cancel_amount){                
                 this.ROnline_status = true;
                 this.ROffline_status = true;
               }
              
            }else{             
              this.ROnline_status = false;
              this.ROffline_status = true;
              this.form.return_amount_type= 'refund_offline';   
            }
          }else if(paymentCount > 1){
              this.ROnline_status = false;
              this.ROffline_status = true;    
              this.form.return_amount_type= 'refund_offline';    
          }          
        });
    },
    createDeliveryOrder: function() {                
      this.form.order_id = this.$route.params.order_id; 

       let  amount = this.cancelAmount > this.paid_amount ? parseFloat(this.paid_amount).toFixed(2) : this.cancelAmount;
       this.form.credit_memo_amount = amount;
      this.form.type = 'cancel'
        if(this.form.payment_status == 'paid'){
                  this.form.type = 'refund'
            }   
      if( this.form.return_amount_type == 'refund_offline'){  
      this.$bvModal.msgBoxConfirm('Refund amount ' + amount + ' save in credit memo  ?', {
              title: 'Please Confirm',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){ 
                 this.form.post(this.api_url).then((response) => {
                  if(response.status){
                      this.$router.push({ path: '/all/order/list' });
                      this.loading=false;
                      this.$showResponse('success', response.message);
                  }
                }).catch((error) => {
                    this.loading = false;
                      console.log(error)
                    })
              }else{
                return false;
              }
            })

       }else if( this.form.return_amount_type == 'refund_online'){
        
        let payment = this.payments;
        this.$bvModal.msgBoxConfirm('Refund ' + amount + ' send account is '+ payment[0].brand +'****'+ payment[0].last_4+ ' ?', {
              title: 'Please Confirm',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){ 
                 this.form.post(this.api_url).then((response) => {
                  if(response.status){
                      this.$router.push({ path: '/all/order/list' });
                      this.loading=false;
                      this.$showResponse('success', response.message);
                  }
                }).catch((error) => {
                    this.loading = false;
                      console.log(error)
                    })
              }else{
                return false;
              }
            })
          }else{
              this.form.post(this.api_url).then((response) => {
                  if(response.status){
                      this.$router.push({ path: '/all/order/list' });
                      this.loading=false;
                      this.$showResponse('success', response.message);
                  }
                }).catch((error) => {
                    this.loading = false;
                      console.log(error)
                    })
          }
    
    }, 
    printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    },
   
  }
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;margin-top:30px;margin-left: 10px;
    }
     .return_product .el-input__inner {
            /* padding-left: 0px;
            padding-right: 8px; */
            border: 1px solid #3699ff;
            text-align: end !important;
        }
         .refund_method{
        background-color: aliceblue;
        padding: 10px 10px 10px 30px;
      }
     

    @media print {
       
        .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 

    .refund_method{
    background-color: aliceblue;
    padding: 10px 10px 10px 30px;
    }

    
    

}
 
</style>